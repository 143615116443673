import React from "react";
import { useLocation } from "react-router-dom";
import FullPage from "../../components/FullPage";
import BemVindo from "../../components/BemVindo";
import SaibaMais from "./Section/SaibaMais";
import usePermission from "../../Hooks/usePermission";

const MaterialPage = (props) => {
  const { getShowTo } = usePermission();
  const location = useLocation();
  let path = location.pathname

  let config = {
    parentUrl: path,
    parentName: "CONTEUDOS",
    parentComponent: BemVindo,
    routes: [
      {
        route: "saiba-mais",
        text: "SAIBA MAIS",
        component: SaibaMais,
        show: getShowTo("MATERIAIS_DE_PROSPECCAO")
      },
    ],
  };

  return <FullPage {...config} />;
};

export default MaterialPage;
